<template>
<div class="container">
<div class="result-form">
    
        <div class="error"  v-if="err">
            <p> ** Incorrect! Please try again.</p>
        </div>   

        <div class="result" v-if="!showResult">
            <div class="form">
                <form @submit.prevent="viewResult">
                    <label for="">INDEX NUMBER</label><br>
                    <input type="text" v-model="ind" placeholder="Index Number" required><br>
                    <button>Fetch My Result</button>
                </form>
            </div>            
        </div>

        <div class="marks" v-if="showResult">
        <div class="hd">STATEMENT OF MARKS<hr></div>
            <div class="wrapper" v-for="c9 in Class9" :key="c9.id">
            <div>
                <table v-if="c9.IndexNo == ind" id="tt">
                    <tr>
                        <th>Index No:</th>
                        <td>{{c9.IndexNo}}</td>
                        <th>Name:</th>
                        <td>{{c9.Name}}</td>
                    </tr>
                    <tr>
                        <th>Grade:</th>
                        <td>{{c9.Grade}}</td>
                        <th>Section:</th>
                        <td>{{c9.Sec}}</td>

                    </tr>
                </table>
            </div>
            <div>
                <table v-if="c9.IndexNo == ind" id="tbh">
                    <tr bgcolor="#f8f8ff" style="border-top: solid black">
                        <th>SUBJECT</th>
                        <th>CA [30]</th>
                        <th>EXAM [70]</th>
                        <th>TOTAL [100]</th>
                        <th>RESULT</th>
                    </tr>
                    <tr>
                        <td>English</td>
                        <td>{{c9.EngCA}}</td>
                        <td>{{c9.EngE}}</td>
                        <td>{{c9.EngT}}</td>
                        <td>{{c9.EngR}}</td>
                    </tr>
                    <tr>
                        <td>Dzongkha</td>
                        <td>{{c9.DzoCA}}</td>
                        <td>{{c9.DzoE}}</td>
                        <td>{{c9.DzoT}}</td>
                        <td>{{c9.DzoR}}</td>
                    </tr>
                    <tr>
                        <td>Mathematics</td>
                        <td>{{c9.MatCA}}</td>
                        <td>{{c9.MatE}}</td>
                        <td>{{c9.MatT}}</td>
                        <td>{{c9.MatR}}</td>
                    </tr>
                    <tr bgcolor="#f8f8ff">
                        <th>SUBJECT</th>
                        <th>CA [40]</th>
                        <th>EXAM [60]</th>
                        <th>TOTAL [100]</th>
                        <th>RESULT</th>
                    </tr>
                    <tr>
                        <td>Science</td>
                        <td>{{c9.SciCA}}</td>
                        <td>{{c9.SciE}}</td>
                        <td>{{c9.SciT}}</td>
                        <td>{{c9.SciR}}</td>
                    </tr>
                    <tr>
                        <td>HCG</td>
                        <td>{{c9.HCGCA}}</td>
                        <td>{{c9.HCGE}}</td>
                        <td>{{c9.HCGT}}</td>
                        <td>{{c9.HCGR}}</td>
                    </tr>
                    <tr>
                        <td>Economics</td>
                        <td>{{c9.EcoCA}}</td>
                        <td>{{c9.EcoE}}</td>
                        <td>{{c9.EcoT}}</td>
                        <td>{{c9.EcoR}}</td>
                    </tr>
                    
                    <tr bgcolor="#f8f8ff">
                        <th>SUBJECT</th>
                        <th>CA [60]</th>
                        <th>EXAM [40]</th>
                        <th>TOTAL [100]</th>
                        <th>RESULT</th>
                    </tr>
                    <tr>
                        <td>ICT</td>
                        <td>{{c9.ICTCA}}</td>
                        <td>{{c9.ICTE}}</td>
                        <td>{{c9.ICTT}}</td>
                        <td>{{c9.ICTR}}</td>
                    </tr>
                </table>
                </div>
                <div>
                <table v-if="c9.IndexNo == ind" id="ftr">
                    <tr style="border-bottom: solid black">
                       <th>Total Marks: &nbsp;{{c9.Tot}}</th>
                       <th>Percentage: &nbsp;{{c9.Per}}</th>
                       <th>Result: &nbsp;{{c9.Result}}</th>
                    </tr>
                </table>
            </div>
            </div>
            <div class="note">Note: To PASS, you must score minimum of 40% in both CA and Written exam.</div>
        </div>
        
</div>
</div>

</template>

<script>
import { ref } from '@vue/reactivity'
import class9Marks from '../../assets/data/Class9.json'

export default {
        setup(){
            
            const ind = ref('')
            const Class9 = ref(class9Marks)
            const showResult = ref(false)
            const err = ref(false)

            const viewResult = ()=>{
                 
                class9Marks.every(element => {
                if (ind.value == element.IndexNo) {
                    showResult.value = !showResult.value
                    err.value = false
                    return false             
                
                 } 
                
                else{                 
                err.value = true
                return true                
                    }
             
                 } );
            }
            return {ind, Class9,showResult,err, viewResult}
        }
}
</script>

<style scoped>
    @import '@/assets/css/style.css';
</style>