<template>
<div class="container">
<div class="result-form">
        <div class="error"  v-if="err">
            <p> ** Incorrect! Please try again.</p>
        </div>   
        <div class="result" v-if="!showResult">
            <div class="form">
                <form @submit.prevent="viewResult">
                    <label for="">INDEX NUMBER</label><br>
                    <input type="text" v-model="ind" placeholder="Index Number" required><br>
                    <button>Fetch My Result</button>
                </form>
            </div>            
        </div>
        <div class="marks" v-if="showResult">
        <div class="hd">STATEMENT OF MARKS<hr></div>
            <div class="wrapper" v-for="C11S in Class11S" :key="C11S.id">
            <div>
                <table v-if="C11S.IndexNo == ind" id="tt">
                    <tr>
                        <th>Index No:</th>
                        <td>{{C11S.IndexNo}}</td>
                        <th>Name:</th>
                        <td>{{C11S.Name}}</td>
                    </tr>
                    <tr>
                        <th>Grade:</th>
                        <td>{{C11S.Grade}}</td>
                        <th>Section:</th>
                        <td>{{C11S.Sec}}</td>

                    </tr>
                </table>
            </div>
            <div>
                <table v-if="C11S.IndexNo == ind" id="tbh">
                    <tr bgcolor="#f8f8ff" style="border-top: solid black">
                        <th>SUBJECT</th>
                        <th>CA [20]</th>
                        <th>EXAM [80]</th>
                        <th>TOTAL [100]</th>
                        <th>RESULT</th>
                    </tr>
                    <tr>
                        <td>English</td>
                        <td>{{C11S.EngCA}}</td>
                        <td>{{C11S.EngE}}</td>
                        <td>{{C11S.EngT}}</td>
                        <td>{{C11S.EngR}}</td>
                    </tr>
                    <tr>
                        <td>Dzongkha</td>
                        <td>{{C11S.DzoCA}}</td>
                        <td>{{C11S.DzoE}}</td>
                        <td>{{C11S.DzoT}}</td>
                        <td>{{C11S.DzoR}}</td>
                    </tr>
                    <tr>
                        <td>Pure Mathematics</td>
                        <td>{{C11S.PMatCA}}</td>
                        <td>{{C11S.PMatE}}</td>
                        <td>{{C11S.PMatT}}</td>
                        <td>{{C11S.PMatR}}</td>
                    </tr>
                    <tr bgcolor="#f8f8ff">
                        <th>SUBJECT</th>
                        <th>CA [30]</th>
                        <th>EXAM [70]</th>
                        <th>TOTAL [100]</th>
                        <th>RESULT</th>
                    </tr>
                    <tr>
                        <td>Physics</td>
                        <td>{{C11S.PhyCA}}</td>
                        <td>{{C11S.PhyE}}</td>
                        <td>{{C11S.PhyT}}</td>
                        <td>{{C11S.PhyR}}</td>
                    </tr>
                    <tr>
                        <td>Chemistry</td>
                        <td>{{C11S.CheCA}}</td>
                        <td>{{C11S.CheE}}</td>
                        <td>{{C11S.CheT}}</td>
                        <td>{{C11S.CheR}}</td>
                    </tr>
                    <tr>
                        <td>Biology</td>
                        <td>{{C11S.BioCA}}</td>
                        <td>{{C11S.BioE}}</td>
                        <td>{{C11S.BioT}}</td>
                        <td>{{C11S.BioR}}</td>
                    </tr>
                    <tr bgcolor="#f8f8ff">
                        <th>SUBJECT</th>
                        <th>CA [50]</th>
                        <th>EXAM [50]</th>
                        <th>TOTAL [100]</th>
                        <th>RESULT</th>
                    </tr>
                    <tr style="border-bottom: solid black">
                        <td>ICT</td>
                        <td>{{C11S.ICTCA}}</td>
                        <td>{{C11S.ICTE}}</td>
                        <td>{{C11S.ICTT}}</td>
                        <td>{{C11S.ICTR}}</td>
                    </tr>
                </table>
                </div>
                <div>
                <table v-if="C11S.IndexNo == ind" id="ftr">
                    <tr style="border-bottom: solid black">
                       <th>Total Marks: &nbsp;{{C11S.Tot}}</th>
                       <th>Percentage: &nbsp;{{C11S.Per}}</th>
                       <th>Result: &nbsp;{{C11S.Result}}</th>
                    </tr>
                </table>
            </div>
            </div>
            <div class="note">Note: To PASS, you must score minimum of 40% in both CA and Written exam.</div>
        </div>
</div>
</div>
</template>
<script>
import { ref } from '@vue/reactivity'
import Class11SMarks from '../../assets/data/Class11S.json'
export default {
        setup(){
            
            const ind = ref('')
            const Class11S = ref(Class11SMarks)
            const showResult = ref(false)
            const err = ref(false)

            const viewResult = ()=>{
                 
                Class11SMarks.every(element => {
                if (ind.value == element.IndexNo) {
                    showResult.value = !showResult.value
                    err.value = false
                    return false             
                 } 
                else{                 
                err.value = true
                return true                
                    }
                 } );
            }
            return {ind,Class11S,showResult,err,viewResult}
        }
}
</script>
<style scoped>
    @import '@/assets/css/style.css';
</style>