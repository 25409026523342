<template>
  <footer>
     <p>Copyright &copy; Yangchenphug Higher Secondary School - 2022</p>
  </footer>
</template>

<script>
export default {

}
</script>

<style>
  @import '@/assets/css/style.css';
</style>