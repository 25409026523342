<template>
  <section>
    <div class="left">
     <img src="../assets/logo.png" alt=""> 
      <h2 align="left">ANNUAL Examination Result - 2022</h2>
              <p><label for="section">Select your class</label>
              <select id="section" v-model="sec">
                  <option value = "" selected="selected">Select Your Class</option>
                  <option v-for="section in sections" :key="section.id">{{section}}</option>
              </select></p>
    </div>
   <div class="title" v-if="!sec">
     <h1>Yangchenphug Higher Secondary School</h1>
     <p>Perseverance for <span>Excellence</span></p>
   </div>
  
   
    <div class="right">
        <ClassNine v-show="sec == 'Class 9'"/>
        <Class11A v-show="sec == 'Class 11 Arts'"/>
        <Class11C v-show="sec == 'Class 11 Commerce'"/>
        <Class11S v-show="sec == 'Class 11 Science'"/>
          
    </div>
  </section>
</template>

<script>
import { ref } from '@vue/reactivity'
import ClassNine from './result/ClassNine.vue'
import Class11S from './result/Class11S.vue'
import Class11C from './result/Class11C.vue'
import Class11A from './result/Class11A.vue'

export default {
  components:{ClassNine, Class11S, Class11C, Class11A},
  setup(){
    const title = ref('Spring Test 2022')
    const sec = ref('')
    const sections = ref(['Class 9','Class 11 Science','Class 11 Arts','Class 11 Commerce'])
    return {title, sections,sec}
  }
}
</script>

<style scoped>
  @import '@/assets/css/style.css';
</style>